<template>
  <AccordionRoot v-model="expanded" class="flex flex-col">
    <AccordionItem
      v-for="(filter, index) in parameterSet"
      :key="filter.id"
      :value="filter.id"
    >
      <AccordionHeader class="flex flex-col">
        <AccordionTrigger class="group flex gap-2 p-2 text-gray-600">
          <ChevronDownIcon
            class="mt-0.5 h-4 w-4 shrink-0 transition-transform group-data-[state=closed]:-rotate-90"
          />

          <span
            class="overflow-hidden break-words text-left text-sm font-medium truncate"
          >
            {{ filter.name }}
          </span>

          <div
            :class="[
              `mx-1 mt-1.5 h-2 w-2 shrink-0 rounded-full bg-blue-500 transition-opacity`,
              isFilterChanged(filter) ? `opacity-100` : `opacity-0`,
            ]"
          ></div>
        </AccordionTrigger>
      </AccordionHeader>
      <AccordionContent class="content">
        <hr class="border-gray-200" />

        <CheckboxFilter
          v-if="filter.type === 'checkboxes'"
          :model-value="state[filter.id]"
          :snapshot="lastChanged"
          :aggregates="aggregates[filter.id]"
          :filter="filter"
          @update:model-value="state = { ...state, [filter.id]: $event }"
        />
        <DateFilter
          v-else-if="filter.type === 'date'"
          :model-value="state[filter.id] ?? []"
          @update:model-value="state = { ...state, [filter.id]: $event }"
        />
      </AccordionContent>

      <hr v-if="index !== parameterSet.length - 1" class="border-gray-200" />
    </AccordionItem>
  </AccordionRoot>
</template>

<script setup lang="ts">
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from "radix-vue";
import { ref } from "vue";

import ChevronDownIcon from "@/icons/heroicons/chevron-down-micro.svg";
import { Aggregate } from "@/types/aggregate";

import { Parameter, ParameterSet } from "../lib";
import CheckboxFilter from "./CheckboxFilter.vue";
import DateFilter from "./DateFilter.vue";
import { CheckboxSnapshot } from "./types";

const state = defineModel<Parameter<string>>({ required: true });
const expanded = defineModel<string[]>("expanded", { default: [] });

defineProps<{
  parameterSet: ParameterSet[];
  aggregates: Record<string, Aggregate<string>[] | undefined>;
}>();

const lastChanged = ref<CheckboxSnapshot | null>(null);

const isFilterChanged = (filter: ParameterSet) => {
  const values = state.value[filter.id];

  if (!values || values.length === 0) {
    return false;
  }

  if (
    filter.type === "checkboxes" &&
    values.length === filter.checkboxes.length
  ) {
    return false;
  }

  return true;
};
</script>

<style scoped>
.content {
  overflow: hidden;
}
.content[data-state="open"] {
  animation: slide-down 100ms ease-out;
}
.content[data-state="closed"] {
  animation: slide-up 100ms ease-in;
}

@keyframes slide-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slide-up {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
</style>
