import { defineStore } from "pinia";
import { getCurrentInstance, onMounted } from "vue";

import URLS from "@/autogen/urls";
import api from "@/lib/api/internal-base";
import whenNewsletterChanges from "@/lib/whenNewsletterChanges";

import {
  type List,
  type ListResource,
  useAggregate,
  useListResource,
  useListUpdateResource,
} from "./base";

export const updateResourceOnMount = <M, O, R extends ListResource<M, O, any>>(
  resource: R
): R => {
  const currentInstance = getCurrentInstance();
  if (currentInstance) {
    onMounted(() => {
      resource.list();
    }, currentInstance);
  }
  return resource;
};

export type ILazyInitializer<T> = () => T;

export class Lazy<T> {
  private instance: T | null = null;
  private initializer: ILazyInitializer<T>;

  constructor(initializer: ILazyInitializer<T>) {
    this.initializer = initializer;
  }

  public get value(): T {
    if (this.instance == null) {
      this.instance = this.initializer();
    }

    return this.instance;
  }
}

export const compose = <T>(
  obj: T
): {
  then: (next: ((arg: T) => T)[]) => T;
} => {
  return {
    then: (next: ((arg: T) => T)[]) => {
      return next.reduce((acc, n) => n(acc), obj);
    },
  };
};

export const updateResourceOnNewsletterChange = <
  M,
  O,
  R extends ListResource<M, O, any>
>(
  resource: R
): R => {
  whenNewsletterChanges(() => {
    resource.list();
  });
  return resource;
};

const constructMentionResource = () => {
  const resource = useListResource(
    api.path("/mentions").method("get").create(),
    {
      expand: ["subscriber", "automation", "email"],
    }
  );
  const { fieldToAggregateCount, fieldToGlobalAggregateCount, aggregate } =
    useAggregate(URLS.aggregate("mention"));
  return {
    ...resource,
    fieldToAggregateCount,
    fieldToGlobalAggregateCount,
    aggregate,
  };
};

const constructEndpoint = <
  M,
  O,
  P extends {
    ordering?: O;
  }
>(
  obj: List<M, P>
): Lazy<ListResource<M, O, P>> =>
  new Lazy(() =>
    compose(useListResource(obj)).then([updateResourceOnNewsletterChange])
  );

export const useStore = defineStore("internal-openapi-mirror", () => {
  return {
    "/invoices": constructEndpoint(
      api.path("/invoices").method("get").create()
    ),
    "/subscriber-imports": constructEndpoint(
      api.path("/subscriber-imports").method("get").create()
    ),
    "/mentions": new Lazy(() =>
      compose(constructMentionResource()).then([
        updateResourceOnNewsletterChange,
      ])
    ),
    "/products": constructEndpoint(
      api.path("/products").method("get").create()
    ),
    "/subscriptions": constructEndpoint(
      api.path("/subscriptions").method("get").create()
    ),
    "/utm-sources": constructEndpoint(
      api.path("/utm-sources").method("get").create()
    ),
    "/referrer-urls": constructEndpoint(
      api.path("/referrer-urls").method("get").create()
    ),
    "/conversations": new Lazy(() =>
      compose(
        useListUpdateResource(
          api.path("/conversations").method("get").create(),
          api.path("/conversations/{conversation_id}").method("patch").create(),
          {},
          {
            expand: ["subscriber", "automation", "email"],
          }
        )
      ).then([updateResourceOnMount, updateResourceOnNewsletterChange])
    ),
  };
});
